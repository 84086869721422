<script type="text/javascript" src="cordova.js"></script>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import DatePicker from "vue2-datepicker";

import Swal from "sweetalert2";
import axios from 'axios';
import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

//import { calendarEvents, categories } from "../calendar/data-calendar";

/**
 * Calendar component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description" }],
  },
  components: { FullCalendar, Layout, PageHeader, DatePicker },
  data() {
    return {
      title: "",
      items: [],

      platform:         null,
      diamaterSource:   {},
      heightSource:     {},
      longitudeSource:  {},
      weightSource:     {},
      sizeSource:       {},
      liftSource:       {},
      timeSource:       {},
      distanceSource:   {},
      chartConfig:      {},
      sources:          [],
      series:           [],

      member:           {},

      newEventID:           "",
      schedules:            [],
      groups:               [],
      eventTypes:           [
        { name: "Re-Schedule", value:"1" },
        { name: "Cancelation", value:"2" },
        { name: "Absence", value:"3" }
      ],
      selectedSchedule:     {},
      selectedGroup:        {},
      eventData:            {},
      form:                 {
        id_member:          "",
        id_userAccount:     "",
        id_eventType:       "",
        id_schedule:        "",
        de_schedule:        "",
        id_group:           "",
        nm_group:           "",
        dt_reschedule:      "",
        dt_bookingDate:     "",
        dt_cancellation:    "",
        dt_startingAbsence: "",
        dt_endingAbsence:   "",
      },
      validToken:           false,
      options:{
        format: 'YYYY-MM-DD',
      },


      calendarEvents: [],
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        initialEvents: this.calendarEvents,
        editable: false,
        droppable: false,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      categories: [],
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
      },
      editevent: {
        editTitle: "",
        editcategory: "",
      },
    };
  },
  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted(){
    console.log(new Date().setDate(new Date().getDate() + 2));
    document.addEventListener("deviceready", this.onDeviceReady, false);
    this.getMemberByID();
    this.getPerformanceByMemberID();

  },
  methods: {
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        this.platform = platform;
      }
    },

    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      var error = false;
      this.submitted = true;

      error;

      if( this.form.id_eventType == 1 ){
        if( this.selectedSchedule.id_schedule != ""){
          //Effective date for the reschedule
          this.form.dt_reschedule = this.newEventData.dateStr;
          this.form.id_schedule = this.selectedSchedule.id_schedule;
          this.form.de_schedule = 'FROM ' + this.selectedSchedule.dt_fromTime + ' TO ' + this.selectedSchedule.dt_toTime;

          if( this.groups.length > 0){
            if( this.selectedGroup.id_group == "" ){
              error = true;
              Swal.fire("Ooops", "Please select a group.", "error");
            }else{
              this.form.id_group = this.selectedGroup.id_group;
              this.form.nm_group = this.selectedGroup.schedule;

              this.eventData = {
                id: "",
                title: "RE-SCHEDULE",
                start: this.newEventData.date,
                end: this.newEventData.date,
                classNames: ["bg-info"],
              };

              console.log(this.saveBooking());
            }
          }else{
            this.eventData = {
              id: "",
              title: "RE-SCHEDULE",
              start: this.newEventData.date,
              end: this.newEventData.date,
              classNames: ["bg-info"],
            };

            console.log(this.saveBooking());
          }
        }else{
          if( this.groups.length > 0){
            if(this.form.id_schedule == "" || this.form.id_group == ""){
              error = true;
              Swal.fire("Ooops", "Schedule and/or group cannot be empty. Please select a schedule and/or group.", "error");
            }
          }else{
            if( this.form.id_schedule == "" ){
              error = true;
              Swal.fire("Ooops", "Please select a schedule.", "error");
            }
          }
        }
      }

      if( this.form.id_eventType == 2 ){
        this.form.dt_cancellation = this.newEventData.dateStr;

        this.eventData = {
          id: "",
          title: "CANCEL ASSISTANCE",
          start: this.newEventData.date,
          end: this.newEventData.date,
          classNames: ["bg-warning"],
        };

        this.saveBooking();
      }

      if( this.form.id_eventType == 3 ){
        this.form.dt_startingAbsence = this.newEventData.dateStr;

        if( this.form.dt_startingAbsence != "" && this.form.dt_endingAbsence != ""){
          //var date1 = new Date(this.form.dt_startingAbsence);
          var date2 = new Date(this.form.dt_endingAbsence);

          //Calculating difference in time
          //let differenceInTime = date2.getTime() - date1.getTime();
          //Calculating the no. of days between
          //let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
          this.eventData ={
            id: "",
            title: "ABSENCE",
            start: this.newEventData.date,
            end: date2.setDate(date2.getDate() + 1),
            classNames: ["bg-danger"],
          };

          this.saveBooking();        
        }else{
          if( new Date(this.form.dt_endingAbsence) < new Date(this.form.dt_startingAbsence) ){
            error = true;
            Swal.fire("Ooops", "Absence ending date cannot be minor than Absence starting date", "error")
          }

          if( this.form.dt_endingAbsence == this.form.dt_startingAbsence ){
            error = true;
            Swal.fire("Ooops", "Absence Starting date and Absence ending date is the same date, if you want to CANCEL ASSISTANCE, we recommend to select option CANCEL ASSISTANCE in the 'Booking or absence type' field.", "error")
          }

          if( this.form.dt_endingAbsence == "" || this.form.dt_startingAbsence == "" ){
            error = true;
            Swal.fire("Ooops", "Absence Starting date and/or Absence ending date cannot be empty.", "error");
          }
        }
      }

      /*
      if( this.submitted && !error){
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
          const title = this.event.title;
          const category = this.event.category;
          let calendarApi = this.newEventData.view.calendar;

          this.currentEvents = calendarApi.addEvent({
            id: this.newEventData.length + 1,
            title,
            start: this.newEventData.date,
            end: this.newEventData.date,
            classNames: [category],
          });
          this.successmsg();
          this.showModal = false;
          this.newEventData = {};
        }
        this.submitted = false;
        this.event = {};
      }
      */
    },

    async saveBooking(){
      let app = this;
      app;
      Swal.fire({
        title: "Saving.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      //Adding the member
      this.form.id_member       = this.user.user.id_user;
      this.form.id_userAccount  = this.user.user.id_user;

      axios.post(window.serverPath+'dist/mvw/controllers/bookings.php', {
        request: 'saveBooking',
        data: this.form,
        token: this.user.token,
      })
      .then(function (response) {
        if( response.status == 200 ){
          Swal.close();
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            if( !isNaN(response.data) ){
              app.eventData.id = response.data;
              app.addEvent(app.eventData);
              Swal.fire("Good job!", "Your " + app.eventData.title + " has been booked. Thanks", "success");
              return response.data;
            }else{
              Swal.fire("Ooops!", "Seems like some error ocurr, please try again.", "error");
            }
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
        }
      })
      .catch(function (error) {
        Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
      });
    },

    addEvent(eventData){
      let calendarApi = this.newEventData.view.calendar;

      this.currentEvents = calendarApi.addEvent(eventData);
      this.successmsg();
      this.showModal = false;
      this.newEventData = {};
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      const editTitle = this.editevent.editTitle;
      const editcategory = this.editevent.editcategory;

      this.edit.setProp("title", editTitle);
      this.edit.setProp("classNames", editcategory);
      this.successmsg();
      this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.editevent.editTitle = this.edit.title;
      this.editevent.editcategory = this.edit.classNames[0];
      this.eventModal = true;

      console.log(info.event.record);
    },

    closeModal() {
      this.eventModal = false;
    },

    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },

    async getSchedulesByWeekDays(){
      var date = new Date(this.newEventData.dateStr);

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/schedules.php',
        {
          params: {
            request: 'getSchedulesByWeekDays',
            token: this.user.token,
            numWeekDays: date.getDay()
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.schedules = response.data;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    async getGroupsByScheduleID(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/groups.php',
        {
          params: {
            request: 'getGroupsByScheduleID',
            token: this.user.token,
            id_schedule: this.selectedSchedule.id_schedule
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.groups = response.data;
          //this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    prepareData(){
      switch( this.form.id_eventType ){
        case "1": this.getSchedulesByWeekDays(); break;
        case "2": ""; break;
        case "3": this.form.dt_startingAbsence = this.newEventData.dateStr;
      }
    },

    async getPerformanceByMemberID(){
      //let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/performances.php',
        {
          params: {
            request: 'getPerformanceByMemberID',
            id:13,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
            Swal.close();
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              if( Array.isArray(response.data) && response.data.length > 0 ){
                

                this.performances   = response.data;

                var colors          = ['#556ee6', '#f46a6a', '#34c38f', '#fcb92c', '#fc2ceb', '#34495e', '#95a5a6', '#d35400'];
                
                this.diameterSeries  = this.getPerformanceSeries('num_diameter');
                this.heightSeries    = this.getPerformanceSeries('num_height');
                this.longitudeSeries = this.getPerformanceSeries('num_longitude');
                this.weightSeries    = this.getPerformanceSeries('num_weight');
                this.sizeSeries      = this.getPerformanceSeries('num_size');
                this.liftSeries      = this.getPerformanceSeries('num_lift');
                this.timeSeries      = this.getPerformanceSeries('num_time');
                this.distanceSeries  = this.getPerformanceSeries('num_distance');

                this.diamaterSource   = this.setSourceChart( this.getPerformanceSeries('num_diameter'), colors[0] );
                this.heightSource     = this.setSourceChart( this.getPerformanceSeries('num_height'), colors[1] );
                this.longitudeSource  = this.setSourceChart( this.getPerformanceSeries('num_longitude'), colors[2] );
                this.weightSource     = this.setSourceChart( this.getPerformanceSeries('num_weight'), colors[3] );
                this.sizeSource       = this.setSourceChart( this.getPerformanceSeries('num_size'), colors[4] );
                this.liftSource       = this.setSourceChart( this.getPerformanceSeries('num_lift'), colors[5] );
                this.timeSource       = this.setSourceChart( this.getPerformanceSeries('num_time'), colors[6] );
                this.distanceSource   = this.setSourceChart( this.getPerformanceSeries('num_distance'), colors[7] );

                this.sources = [
                  {title: "Diameter performance",   source: this.diamaterSource },
                  {title: "Height performance",     source: this.heightSource },
                  {title: "Longitude performance",  source: this.longitudeSource },
                  {title: "Weight performance",     source: this.weightSource },
                  {title: "Size performance",       source: this.sizeSource },
                  {title: "Lift performance",       source: this.liftSource },
                  {title: "Time performance",       source: this.timeSource },
                  {title: "Distance performance",   source: this.distanceSource }
                ];

                this.platform = {};

                if(this.platform != null){
                  var categories      = this.getPerformanceSeries('dt_performance');
                  var xaxis           = { categories: categories };

                  this.series = [
                    { name: "Weight", data: this.weightSeries},
                    { name: "Size", data: this.sizeSeries},
                    { name: "Lift", data: this.liftSeries},
                    { name: "Time", data: this.timeSeries},
                    { name: "Distance", data: this.distanceSeries}
                  ];

                  this.chartConfig = {
                    series: this.series,
                    chartOptions: {
                      chart: {
                          zoom:     { enabled: true },
                          toolbar:  { show: false },
                          export: {
                            csv: {
                              filename: "my_performance"+(new Date()).toISOString()+".csv",
                              columnDelimiter: ',',
                              headerCategory: 'category',
                              headerValue: 'value'
                            },
                            svg: {
                              filename: "my_performance"+(new Date()).toISOString()+".svg",
                            },
                            png: {
                              filename: "my_performance"+(new Date()).toISOString()+".png",
                            }
                          },
                      },
                      colors: ['#556ee6', '#f46a6a', '#34c38f', '#fcb92c', '#fc2ceb', '#34495e', '#95a5a6', '#d35400'],
                      dataLabels: { enabled: false },
                      stroke: {
                          width: [3,3,3,3,3],
                          curve: 'straight',
                          dashArray: [0,0,0,0,0]
                      },
                      title: { align: 'left' },
                      markers: {
                          size: 5,
                          hover: {
                              sizeOffset: 6
                          }
                      },
                      xaxis: xaxis,
                      tooltip: {
                          y: [
                            { title: { formatter: function (val) { return val + " (cm)" } } },
                            { title: { formatter: function (val) { return val + " (mt)"  } } },
                            { title: { formatter: function (val) { return val + " (cm)"  } } },
                            { title: { formatter: function (val) { return val + " (kg)"  } } },
                            { title: { formatter: function (val) { return val + ""  } } },
                            { title: { formatter: function (val) { return val + " (kg)"  } } },
                            { title: { formatter: function (val) { return val + " (minutes)"  } } },
                            { title: { formatter: function (val) { return val + " (mt)"  } } },
                          ]
                      },
                      grid: { borderColor: '#f1f1f1', }
                    }
                  }
                }
              }
            }
          }else{
            Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
          }
      });
    },

    getPerformanceSeries(serie){
      var array = [];
      this.performances.forEach(function(progress){
        for(var key in progress){
          if( key == serie ){
            if( !isNaN(progress[key]) ){
              array.push(progress[key]);
            }else{
              array.push(0.00);
            }
          }
        }
      });
      return array;
    },

    setSourceChart(chartData, color){
      return {
        series: [
          {
            data: chartData
          }
        ],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            width: [3],
            curve: "smooth"
          },
          colors: [color],
          tooltip: {
            fixed:  {enabled: false},
            x:      {show: true},
            marker: {show: true},
            y:      { title: { formatter: function(val) {return val; } } },
          }
        }
      };
    },

    async getMemberByID(){
      //let app = this;

      Swal.fire({
        title: "Loading member data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/members.php',
        {
          params: {
            request: 'getMemberByID',
            id: 13,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.member.id_pool           = response.data.id_pool;
          this.member.id_coach          = response.data.id_coach;
          this.member.id_group          = response.data.id_group;
          this.member.id_schedule       = response.data.id_schedule;
          this.member.id_team           = response.data.id_team;
          this.member.id_training       = response.data.id_training;
          this.member.id_status         = response.data.id_status;
          this.member.de_gender         = response.data.de_gender;
          this.member.nm_member         = response.data.nm_member;
          this.member.nm_lastName       = response.data.nm_lastName;
          this.member.num_age           = response.data.num_age;
          this.member.dt_birthday       = response.data.dt_birthday+" 00:00:00Z";
          this.member.num_phoneNumber   = response.data.num_phoneNumber;
          this.member.de_email          = response.data.de_email;
          this.member_de_materials      = response.data.de_materials;
          this.member.de_specialistOn   = response.data.de_specialistOn;
          this.member.url_photograph    = response.data.url_photograph;
          this.member.dt_registrationDate  = response.data.dt_registrationDate;

          this.member.num_diameter       = response.data.num_diameter;
          this.member.num_height         = response.data.num_height;
          this.member.num_longitude      = response.data.num_longitude;
          this.member.num_weight         = response.data.num_weight;
          this.member.num_size           = response.data.num_size;

          this.member.de_selectedRoutines                   = (response.data.de_selectedRoutines == "" || response.data.de_selectedRoutines == null) ? null : JSON.parse(response.data.de_selectedRoutines);
          //this.members.de_selectedRoutines.de_selectedSets  = JSON.parse(this.members.de_selectedRoutines.de_selectedSets);
          //this.selectedRoutines                           = JSON.parse(response.data.de_selectedRoutines);
          this.selectedMaterials                            = ( Array.isArray(response.data.de_materials) ) ? response.data.de_materials : "" ;

          if(response.data.sn_parentalConsent == 1){
            this.member.sn_parentalConsent = true;
          }

          this.getPerformanceByMemberID();
          this.load ++;

          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to member data! Please load this module again.", "error");
        }
      });
    },

    getWeekDay(arrayDays){
      let weekDays = ["MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"];
      var aux = [];
      var week = "";

      for(var day in arrayDays){
        aux.push( weekDays[day] );
      }

      week = aux.join(", ");
      
      return  week;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">
      <div class="card-body">
        <div class="card-title"><h5>My profile</h5></div>  
        <div class="row">
          <div class="col-4 block-inline"><b>Gender:</b></div>
          <div class="col-8">{{this.member.de_gender}}.</div>
        </div>
        <div class="row">
          <div class="col-4 block-inline"><b>Age:</b></div>
          <div class="col-8">{{this.member.num_age}} years old.</div>
        </div>
        <div class="row">
          <div class="col-4 block-inline"><b>Subs. Date:</b></div>
          <div class="col-8">{{this.member.dt_registrationDate}}</div>
        </div>

        <div class="row">
          <div class="col-4 block-inline"><b>Billing date:</b></div>
          <div class="col-8">Every month on 15th</div>
        </div>
        <div class="row">
          <div class="col-4 block-inline"><b>Billing :</b></div>
          <div class="col-8">$850.00</div>
        </div>
        <div class="row">
          <div class="col-4 block-inline"><b>Status :</b></div>
          <div class="col-8"><span class="text-success">ACTIVE</span></div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12"><h4 class="card-title">My routine(s)</h4></div>

          <div class="col-sm-12" v-for="(routine, index) in member.de_selectedRoutines" :key="index">
            <div role="tablist">
              <b-card no-body class="mb-1 shadow-none">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle="[`accordion-${index}`]"
                      class="text-dark"
                      href="javascript: void(0);"
                    >{{routine.label}}</a>
                  </h6>
                </b-card-header>
                <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <p>{{routine.de_routineDescription}}</p>
                      <div class="row mb-3" v-for="(set, pos) in JSON.parse(routine.de_selectedSets)" :key="pos">
                        <div class="col-sm-12">
                          <h5>{{set.label}}</h5>
                          <p class="m-0 p-0"><span>{{ getWeekDay(JSON.parse(routine.de_selectedDays)) }}</span></p>
                          <p>{{set.de_setDescription}}</p>
                        </div>
                        <div class="col-sm-12">
                          <table class="table table-sm font-size-10 mb-4 table-bordered table-striped" :fixed="true" style="min-width: 300px !important;">
                            <thead class="thead-dark">
                              <tr>
                                <th>{{ $t('menuitems.routines.list.exercise') }}</th>
                                <th>{{ $t('menuitems.exercises.list.description') }}</th>
                                <th>{{ $t('menuitems.sets.list.quantity') }}</th>
                                <th>{{ $t('menuitems.sets.list.meassure') }}</th>
                                <th>{{ $t('menuitems.sets.list.time') }}</th>
                                <th>{{ $t('menuitems.sets.list.unit') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(exercise, p) in JSON.parse(set.de_selectedExercises)" :key="p">
                                <td scope="row" class="pt-3">{{exercise.label}}</td>
                                <td class="pt-3">{{exercise.de_exerciseDescription}}</td>
                                <td class="pt-3">{{exercise.quantity}}</td>
                                <td class="pt-3">{{exercise.meassure}}</td>
                                <td class="pt-3">{{exercise.timesQuantity}} {{exercise.time}}</td>
                                <td class="pt-3">{{exercise.unitsQuantity}} {{exercise.unit}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">

        <div class="row">
          <div class="col-sm-12"><h4 class="card-title">My performance</h4></div>
          <div class="row" v-if="platform == null">
            <div class="col-sm-3" v-for="(source, index) in sources" :key="index">
              <b-card no-body>
                <b-card-body class="bg-light">
                  <b-card-title>
                    <h4 class="card-title">{{source.title}}</h4>
                  </b-card-title>
                  <b-card-text>
                   <apexchart
                      class="apex-charts"
                      height="35"
                      dir="ltr"
                      :series="source.source.series"
                      :options="source.source.chartOptions"
                      type="line"
                    ></apexchart>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <div class="row" v-else>
            <apexchart
              v-if="chartConfig != null"
              class="apex-charts"
              height="380"
              type="line"
              dir="ltr"
              :series="chartConfig.series"
              :options="chartConfig.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12"><h4 class="card-title">Agenda</h4></div>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="app-calendar">
                  <FullCalendar
                    ref="fullCalendar"
                    :options="calendarOptions"
                  ></FullCalendar>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal
          v-model="showModal"
          title="Add New Event"
          title-class="text-black font-18"
          body-class="p-3"
          hide-footer
        >
          <form @submit.prevent="handleSubmit">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="id_eventType">Booking or absence type:</label>
                  <select
                    class="form-control"
                    name="id_eventType"
                    id="id_eventType"
                    v-model="form.id_eventType"
                    @change="prepareData"
                  >
                    <option value="">SELECT BOOKING OR ABSENCE TYPE</option>
                    <option value="1">RE-SCHEDULE</option>
                    <option value="2">CANCEL ASSISTANCE</option>
                    <option value="3">REGISTER ABSENCE</option>
                  </select>
                </div>

                <div class="form-group" v-if="form.id_eventType == 1">
                  <label for="id_schedule">{{ $t('menuitems.members.list.schedule') }}</label>
                  <select
                    class="form-control"
                    name="id_schedule"
                    id="id_schedule"
                    v-model="selectedSchedule"
                    @change="getGroupsByScheduleID"
                  >
                    <option value="">{{ $t('menuitems.members.list.selectSchedule') }}</option>
                    <option v-for="(schedule, index) in schedules" :key="index" :value="schedule">{{"FROM " + schedule.dt_fromTime + " TO " + schedule.dt_toTime}}</option>
                  </select>
                </div>

                <div class="form-group" v-if="form.id_eventType == 1 && groups.length > 0">
                  <label for="id_group">{{ $t('menuitems.members.list.group') }}</label>
                  <select
                    class="form-control"
                    name="id_group"
                    id="id_group"
                    v-model="selectedGroup"
                  >
                    <option value="">{{ $t('menuitems.members.list.selectGroup') }}</option>
                    <option v-for="(group, index) in groups" :key="index" :value="group">{{group.schedule}}</option>
                  </select>
                </div>

                <div class="form-group mb-3" v-if="form.id_eventType == 3">
                  <label for="dt_startingAbsence">Starting absence date:</label>
                  <br />
                  <date-picker
                    v-model="form.dt_startingAbsence"
                    :first-day-of-week="1"
                    lang="en"
                    name="dt_startingAbsence"
                    id="dt_startingAbsence"
                    valueType="format"
                    format="YYYY-MM-DD"
                    :value="form.dt_startingAbsence"
                    disabled
                  >
                  </date-picker>
                </div>

                <div class="form-group mb-3" v-if="form.id_eventType == 3">
                  <label for="dt_endingAbsence">Ending absence date:</label>
                  <br />
                  <date-picker
                    v-model="form.dt_endingAbsence"
                    :first-day-of-week="1"
                    lang="en"
                    name="dt_endingAbsence"
                    id="dt_endingAbsence"
                    valueType="format"
                    format="YYYY-MM-DD"
                    :value="form.dt_endingAbsence"
                  >
                  </date-picker>
                </div>
              </div>
            </div>

            <div class="text-right pt-5 mt-3">
              <b-button variant="light" @click="hideModal">Close</b-button>
              <b-button type="submit" variant="success" class="ml-1"
                >Create event</b-button
              >
            </div>
          </form>
        </b-modal>

        <!-- Edit Modal -->
        <b-modal
          v-model="eventModal"
          title="Edit Event"
          title-class="text-black font-18"
          hide-footer
          body-class="p-0"
        >
          <form @submit.prevent="editSubmit">
            <div class="p-3">
              <label>Details</label>
              <div class="input-group m-b-15">
                <input
                  v-model="editevent.editTitle"
                  class="form-control"
                  type="text"
                />
                <span class="input-group-append">
                  <button type="submit" class="btn btn-success btn-md">
                    <i class="fa fa-check"></i> Save
                  </button>
                </span>
              </div>
            </div>
            <div class="text-right p-3">
              <b-button variant="light" @click="closeModal">Close</b-button>
              <b-button class="ml-1" variant="danger" @click="deleteEvent"
                >Delete</b-button
              >
            </div>
          </form>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>